
import {Box,Text,Heading} from '@chakra-ui/react'
import React from 'react'
import MeinSEO from '../components/SEO'
import Link from '../components/gatsbylink'
export default function Impressum({location}){
        return(
           <>
<MeinSEO title="Impressum"
       description="Impressum der Webseite An den Ufern der Poesie"
      
/>
 
<Box p="2" margin="auto" width="90vw" px="5vw" maxWidth="1100" >
<Heading mt="12" variant="title" letterSpacing={2} >Impressum</Heading>
<Heading variant="title">Kulturelle Erziehung e.V. <br/>Theater Willy Praml</Heading>
<Text fontSize={["lg","1xl","2xl"]}>Waldschmidtstraße 19<br/>
60316 Frankfurt<br/>
Email: theater.willypraml [ at ] .t-online.de<br/>
Tel: 069 43054733<br/>
Fax: 069 43054734 
</Text>

<Text>
Telefonische Kartenreservierung: 069 43054734<br/>
 
</Text>
<Text fontWeight="400">Ticketvorverkauf ab 1. Mai 2022</Text>

<Text>
        Datenschutzbeauftragter:<br/>
        René Broich, K.O.M<br/>
        
</Text>
<Link to="/datenschutz"> <Text>&rarr; Datenschutzbestimmungen</Text></Link>
<Text>Website programmiert von René Broich, K.O.M<br/>
Email: kulturnetz@oberes-mittelrheintal.org
</Text>
</Box>
</>
        )
}
